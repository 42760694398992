import React, { useEffect, useState } from "react"
import store from "../utils/store"
// import Utterances from "utterances-react"

const src = "https://utteranc.es/client.js"
const branch = "master"
const DARK_THEME = "photon-dark"
const LIGHT_THEME = "github-light"

export const Myutterances = ({ repo }) => {
    const rootElm = React.createRef()
    // MIMP
    // 0 for light theme
    // 1 for dark theme

    // console.log(typeof localStorage.getItem("theme"))

    const first_colour_mode =
        typeof window !== "undefined" ? localStorage.getItem("theme") : "0"
    const [themehere, setThemehere] = useState(first_colour_mode)

    useEffect(() => {
        store.listen("theme:change", _ => {
            // only change theme/colour, don't reload DOM or call GitHub API
            if (document.querySelector("iframe.utterances-frame")) {
                document
                    .querySelector("iframe.utterances-frame")
                    .contentWindow.postMessage(
                        {
                            type: "set-theme",
                            theme: (typeof window !== "undefined" && (localStorage.getItem("theme") == 1)) ? DARK_THEME : LIGHT_THEME,
                        },
                        "https://utteranc.es/"
                    )
            }
        })
    }, [])

    useEffect(() => {
        const utterances = document.createElement("script")
        const utterancesConfig = {
            src,
            repo,
            branch,
            theme: themehere == 1 ? DARK_THEME : LIGHT_THEME,
            label: "comment",
            async: true,
            "issue-term": "pathname",
            crossorigin: "anonymous",
        }

        Object.keys(utterancesConfig).forEach(configKey => {
            utterances.setAttribute(configKey, utterancesConfig[configKey])
        })
        if (rootElm.current) {
            rootElm.current.innerHTML = ""
            rootElm.current.appendChild(utterances)
        }
    }, [])

    return (
        <>
            <div className="parent-utt">
                <div ref={rootElm}></div>
            </div>
        </>
    )
}
